import React, { Fragment, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import type { ReactNode } from 'react';

import styles from './dropdown.module.scss';

interface DropdownProps {
  links: {
    id: string;
    item: ReactNode;
  }[];
  parent: ReactNode;
  placement?: 'bottom' | 'top';
}

const Dropdown: React.FC<DropdownProps> = ({
  parent,
  links,
  placement = 'bottom',
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFloatRight, setIsFloatRight] = useState<boolean>(false);
  const dropdown = useRef<HTMLDivElement>(null);

  const handleClick = (): void => {
    setIsOpen(true);
  };

  const handleMouseEnter = (): void => {
    setIsOpen(true);
  };

  const handleMouseLeave = (): void => {
    setIsOpen(false);
  };

  const handleResize = () => {
    if (dropdown?.current && window?.innerWidth) {
      const { left } = dropdown.current.getBoundingClientRect();

      setIsFloatRight(window.innerWidth - left < 200);
    }
  };

  useEffect(() => {
    if (dropdown?.current) {
      handleResize();
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, [dropdown]);

  return (
    <div
      ref={dropdown}
      className={styles.container}
      onMouseLeave={handleMouseLeave}
    >
      <div onClick={handleClick} onMouseEnter={handleMouseEnter}>
        {parent}
      </div>
      {isOpen && (
        <div className={clsx(styles[placement], styles.dropdown)}>
          <div
            className={clsx(styles.dropdown__menu, {
              [styles.dropdown__menu_right]: isFloatRight,
            })}
          >
            {links?.map((link) => (
              <Fragment key={link.id}>{link.item}</Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
