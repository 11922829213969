import React from 'react';

import FormattedDate from '../formatted-date/formatted-date';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import type { IPost } from 'types/model.post';

import IconComment from 'icons/common/icon-comment.svg';

import styles from './post-info.module.scss';

interface IPostInfoProps {
  comments_count: number | undefined;
  date: string | undefined;
  adapt?: boolean;
  blog?: boolean;
  isBlogPage?: boolean;
  mainPost?: IPost;
}

const PostInfo: React.FC<IPostInfoProps> = ({
  date = '',
  comments_count = 0,
  adapt,
  mainPost,
  isBlogPage,
  blog = true,
}) => {
  const t = useTranslations('blog');

  return (
    <div
      className={clsx(styles.container, {
        [styles.adapt]: adapt,
        [styles.mainPost]: mainPost,
        [styles.blogPage]: isBlogPage,
      })}
    >
      <div className={styles.info}>
        <FormattedDate calendarIcon date={date} />
      </div>
      {blog && (
        <div className={styles.info}>
          <IconComment />
          {comments_count} {comments_count === 1 ? t('comment') : t('comments')}
        </div>
      )}
    </div>
  );
};

export default PostInfo;
