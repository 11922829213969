import React from 'react';
import { useRouter } from 'next/navigation';

import clsx from 'clsx';
import type { SyntheticEvent } from 'react';

import styles from './tag.module.scss';

interface TagProps {
  title: string;
  className?: string;
  dark?: boolean;
  link?: string;
}

const Tag: React.FC<TagProps> = ({ title, link, dark = false, className }) => {
  const router = useRouter();

  const redirect = (e: SyntheticEvent) => {
    e.preventDefault();

    if (link) {
      router.push(link);
    }
  };

  return (
    <div
      className={clsx(className, styles.social__tag, {
        [styles.dark]: dark,
      })}
      onClick={(e) => redirect(e)}
    >
      {title}
    </div>
  );
};

export default Tag;
