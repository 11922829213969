import { api } from './api';
import type { IBaseApiResponse } from './api.types';

import type { IPost } from 'types/model.post';

export interface ICommentItemBase {
  created_at: string;
  id: number;
  name: string;
  text: string;
}

export interface ICommentsCountListItem {
  count: number;
  post_id: string;
}

export interface ICommentItem extends ICommentItemBase {
  answers?: ICommentItemBase[];
}

export interface ITagItem {
  id: string;
  name: string;
}

export interface ICommentList {
  items: ICommentItem[];
  paginate: {
    allPages: number;
    count: number;
    hasPages: boolean;
    nextCursor: string | null;
    perPage: number;
    previousCursor: string | null;
  };
}

interface RequestBase {
  state: number;
}

export interface ICommentListResponse extends RequestBase {
  result: ICommentList;
}

export interface ICommentSendResponse extends RequestBase {
  result: ICommentItem;
}

export interface ICommentTagResponse extends RequestBase {
  result: ITagItem[];
}

export interface IRatingSet {
  count: number;
  rating: number;
  user_rating: number;
}

export interface IPostItem {
  alias: string;
  cover: string;
  description: string;
  id: string;
  meta_description: string;
  meta_title: string;
  title: string;
}

export interface IPostListData {
  items: IPostItem[];
}

export interface ISetRatingData {
  postId: number;
  rating: number;
}

export const BlogService = {
  async getCommentList({ id, page }: { id: string; page: number }) {
    return api.get<ICommentListResponse>(`/v1/blog/post/${id}/comments`, {
      params: {
        page,
      },
    });
  },
  async getCommentsCountList(): Promise<ICommentsCountListItem[]> {
    const url = 'https://api-app.cryptomus.com/v1/blog/count-comments';

    try {
      return await fetch(url)
        .then((res) => res.json())
        .then((res) => res.result);
    } catch (error) {
      throw new Error(
        `Error fetching data: ${(error as { message: string }).message}`
      );
    }
  },

  async getExplorerPosts({ locale }: { locale: string }): Promise<IPostItem[]> {
    const url = 'https://api-app.cryptomus.com/v1/blog/post/explorer/list';

    try {
      return await fetch(url, {
        headers: {
          Language: locale,
        },
      })
        .then((res) => res.json())
        .then((res) => res.result.items);
    } catch (error) {
      throw new Error(
        `Error fetching data: ${(error as { message: string }).message}`
      );
    }
  },

  async getLandingPost({
    locale,
    slug,
  }: {
    locale: string;
    slug: string;
  }): Promise<IPost> {
    const url = `https://api-app.cryptomus.com/v1/landing-post/${slug}?locale=${locale}`;

    try {
      return await fetch(url)
        .then((res) => res.json())
        .then((res) => res.result);
    } catch (error) {
      throw new Error(
        `Error fetching data: ${(error as { message: string }).message}`
      );
    }
  },

  async getLandingPostsList(): Promise<string[]> {
    const url = 'https://api-app.cryptomus.com/v1/landing-post/list?all=1';

    try {
      return await fetch(url)
        .then((res) => res.json())
        .then((res) => res.result.items);
    } catch (error) {
      throw new Error(
        `Error fetching data: ${(error as { message: string }).message}`
      );
    }
  },

  async getPost({
    locale,
    slug,
  }: {
    locale: string;
    slug: string;
  }): Promise<IPost> {
    const url = `https://api-app.cryptomus.com/v1/blog/post/${slug}?locale=${locale}`;

    try {
      return await fetch(url)
        .then((res) => res.json())
        .then((res) => res.result);
    } catch (error) {
      throw new Error(
        `Error fetching data: ${(error as { message: string }).message}`
      );
    }
  },

  async getPosts({ locale }: { locale: string }): Promise<IPost[]> {
    const url = `https://api-app.cryptomus.com/v1/blog/post/list?locale=${locale}`;

    try {
      return await fetch(url)
        .then((res) => res.json())
        .then((res) => res.result.items);
    } catch (error) {
      throw new Error(
        `Error fetching data: ${(error as { message: string }).message}`
      );
    }
  },

  async getPostsList(): Promise<string[]> {
    const url = 'https://api-app.cryptomus.com/v1/blog/post/list?all=1';

    try {
      return await fetch(url)
        .then((res) => res.json())
        .then((res) => res.result.items);
    } catch (error) {
      throw new Error(
        `Error fetching data: ${(error as { message: string }).message}`
      );
    }
  },

  async getRating(postId: string, rating: number) {
    return api
      .post<IBaseApiResponse<IRatingSet>>('v1/blog/post/rating/get', {
        id: postId,
        rating,
      })
      .then((data) => data.data.result);
  },

  async getTags(locale: string): Promise<ICommentTagResponse> {
    const url = 'https://api-app.cryptomus.com/v1/blog/tag/list';

    try {
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Language: locale,
        },
        method: 'GET',
      });

      return response.json();
    } catch (error) {
      throw new Error(
        `Error fetching data: ${(error as { message: string }).message}`
      );
    }
  },

  async sendComment({
    id,
    text,
    comment_id,
  }: {
    id: string;
    text: string;
    comment_id?: string;
  }) {
    return api.post<ICommentSendResponse>('v1/blog/comment/create', {
      comment_id,
      id,
      text,
    });
  },

  async setRating(data: ISetRatingData) {
    return api.post<IBaseApiResponse<IRatingSet>>('v1/blog/post/rating/set', {
      id: data.postId,
      rating: data.rating,
    });
  },
};
