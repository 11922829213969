import React from 'react';

import { useLocale } from 'next-intl';

import IconCalendar from 'icons/common/calendar.svg';

import styles from './formatted-date.module.scss';

interface IFormattedDateProps {
  date: string;
  calendarIcon?: boolean;
}

const FormattedDate: React.FC<IFormattedDateProps> = ({
  date,
  calendarIcon = false,
}) => {
  const locale = useLocale();

  const formatter = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  const dateTime = formatter.format(new Date(date));

  return (
    <div className={styles.wrapper}>
      {calendarIcon && <IconCalendar />}
      <time dateTime={dateTime}>{dateTime}</time>
    </div>
  );
};

export default FormattedDate;
